footer {
    padding: 1em;
    padding-top: 0.5em;
    padding-bottom: 2em;
}

footer ul {
    margin: 0;
}

