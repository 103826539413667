:root {
  --foreground: #ddd05d;
  --background: #260f3e;
  --alt: #51d136;
  --shadow: #1c0c24;
  --highlight: #3a1f54;
}

html {
  background-color: var(--background);
}

body {
  margin: 0;
  font-size: 16px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--background);
  color: var(--foreground);
  transition: background 0.2s ease-in-out, color 0.2s ease-in-out;
}



code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}


ul {
    padding: 0;
}

a {
    color: var(--alt);
}

.no-padding {
    padding: 0 !important;
}
.no-margin {
    margin-left: 0 !important;
    margin-right: 0 !important;
    margin: 0 !important;
}

.shadow {
  box-shadow: 4px 4px 6px 0px var(--shadow), -4px -4px 4px 0px var(--highlight);
  transition: color 0.2s ease-in-out, box-shadow 0.2s ease-in-out, background 0.2s ease-in-out;
}

.shadow:hover {
  box-shadow: 4px 4px 6px 1px var(--shadow), -4px -4px 4px 2px var(--highlight);
}

.round {
    border-radius: 2em;
}


h1 {
    font-size: 3em;
}
h2 {
    font-size: 2em;
    font-weight: 300;
}
h3 {
    font-size: 2em;
    margin: 0.25em 0;
}

.section {
    margin-top: 3em;
}

.no-bullets {
    list-style-type: none;
}
