.header-links li {
    display: inline;
}

.logo object {
    width: 200px;
    padding: 0 1em;
}

header {
    display: flex;
    flex-wrap: wrap;
    padding: 0.5em;
    margin-top: 3em;
    margin-bottom: 3em;
    justify-content: center;
}

header .title {
    padding: 0 1em;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
header h1 {
    margin: 0;
}

header h2 {
    margin: 0;
}

navbar {
    background: var(--background);
    position: sticky;
    top: 0;
    height: 3.25em;
}

navbar ul {
    background: inherit;
}

navbar a {
    padding: 1em 0.5em;
    display: inline-block;
}

@media (min-width: 1000px) {
    header {
	justify-content: start;
    }
}


.theme-toggle-wrapper {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    padding: 1em;
    flex-direction: row;
    margin-top: 1em;
}
