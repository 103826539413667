.imageWrapper {
    padding: 0 !important;
}

.imageWrapper a {
    padding: 0 !important;
    margin: 0 !important;
    width: 100%;
}

.imageWrapper img {
    width: 100%;
    padding: 0;
    margin: 0;
}


/* .project { */
/*     margin: 1em 0; */
/*     padding: 1em ; */
/* } */

.project a {
    padding: 0.75em;
    margin-left: -0.75em;
    display: inline-block;
    font-weight: 500;
}

.project {
    padding: 1em !important;
}
.card {
    padding: 1em;
}

.technologies {
    font-style: italic;
}
